@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');

* {
  outline: none;
  scroll-behavior: smooth;
  --tw-backdrop-grayscale: none;
  --b1: none !important;
}

body {
  margin: 0;
  padding: 0;
  background-color: white;
  width: 100%;
  height: 100dvh;
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}

.Header {
  backdrop-filter: blur(16px);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.shrivastavG{
font-family: 'Quicksand', sans-serif;
}

.courseBG{
  background: url("./images/coursebg.png");
}

::selection {
  background-color: rgb(243, 90, 24);
  color: white;
}

::-webkit-scrollbar {
  width: 12px;
  width: 8px;

}

::-webkit-scrollbar-thumb {
  background: #ec4755;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #e92b3b;
}
